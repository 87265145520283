export const icons = [
    'calendar',
    'check',
    'chevron-down',
    'close',
    'envelope',
    'etsy',
    'home',
    'instagram',
    'logo-cou',
    'logo-cou-mini',
    'logo-cou-original',
    'logo-cou-text',
    'logo-tlp',
    'logo-tlp-text',
    'map-marker',
    'menu',
    'moon',
    'plus',
    'search',
    'shape_1',
    'shape_2',
    'shape_3',
    'shape_4',
    'shape_5',
    'shape_6',
    'sort',
    'sort-up',
    'sort-down',
    'sun',
    'theme',
];

export type Icons = typeof icons[number];