export const mockProps = {
  value: undefined,
  name: 'inputname',
  label: 'Input label',
  prependIcon: 'search',
  placeholder: 'Select an option',
  size: 'md',
  block: true,
  disabled: false,
  error: false,
  labelHidden: false,
  options: [
    {
      key: '1',
      value: 'Option 1',
    }, {
      key: '2',
      value: 'Option 2',
    }, {
      key: '3',
      value: 'Option 3',
    }, {
      key: '4',
      value: 'Option 4',
    }, {
      key: '5',
      value: 'Option 5',
    }, {
      key: '6',
      value: 'Option 6',
    },
  ],
};